import { MdDentistry } from "./MdDentistry";
import { MdPromo } from "./MdPromo";
import { MdQuote } from "./MdQuote";
import { Mds360Round } from "./Mds360Round";
import { MdsContentCopyOutlined } from "./MdsContentCopyOutlined";
import { MdsIdCardOutlined } from "./MdsIdCardOutlined";
import { MdsLocalPharmacyOutlined } from "./MdsLocalPharmacyOutlined";
import { MdsRavenOutlined } from "./MdsRavenOutlined";
import { MdsSoundDetectionDogBarkingRound } from "./MdsSoundDetectionDogBarkingRound";
import { MdsSpaOutlined } from "./MdsSpaOutlined";
import { MdsStethoscopeOutlined } from "./MdsStethoscopeOutlined";
import { MdsSurgicalOutlined } from "./MdsSurgicalOutlined";
import { MdsSurgicalSharp } from "./MdsSurgicalSharp";
import { MdsSyringeOutlined } from "./MdsSyringeOutlined";
import { MdsUrgentCare } from "./MdsUrgentCare";
import { MdsVaccinesOutlined } from "./MdsVaccinesOutlined";
import { MdTibia } from "./MdTibia";
import { MdTrust } from "./MdTrust";

export {
  MdAdd,
  MdArrowBack,
  MdArrowForward,
  MdAssignment,
  MdAssignmentTurnedIn,
  MdAttachFile,
  MdAttachMoney,
  MdBrush,
  MdBusiness,
  MdCampaign,
  MdCancel,
  MdChatBubble,
  MdChatBubbleOutline,
  MdCheck,
  MdCheckCircle,
  MdCheckCircleOutline,
  MdCheckroom,
  MdClose,
  MdCloudUpload,
  MdComputer,
  MdContentCut,
  MdCorporateFare,
  MdDescription,
  MdDirectionsCar,
  MdDownload,
  MdEdit,
  MdEmail,
  MdEmergency,
  MdError,
  MdErrorOutline,
  MdEvent,
  MdExpandLess,
  MdExpandMore,
  MdFlashOn,
  MdGroup,
  MdHelpOutline,
  MdHistory,
  MdHome,
  MdInfoOutline,
  MdKeyboardArrowDown,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdKeyboardArrowUp,
  MdLightbulbOutline,
  MdLink,
  MdLiveHelp,
  MdLocalHospital,
  MdLocationOn,
  MdLock,
  MdLogin,
  MdMedicalServices,
  MdMedication,
  MdMoreHoriz,
  MdMoreVert,
  MdOpenInNew,
  MdOutlineAccessTime,
  MdOutlineAccountCircle,
  MdOutlineCalendarMonth,
  MdOutlineCloudUpload,
  MdOutlineCorporateFare,
  MdOutlineDelete,
  MdOutlineEventRepeat,
  MdOutlineInfo,
  MdOutlineManageAccounts,
  MdOutlineSavings,
  MdOutlineStopCircle,
  MdOutlineTimeline,
  MdOutlineVaccines,
  MdPerson,
  MdPets,
  MdPhone,
  MdPlace,
  MdReport,
  MdSchool,
  MdSearch,
  MdSend,
  MdStar,
  MdUpload,
  MdVisibility,
  MdVisibilityOff,
  MdWarning,
} from "react-icons/md";

export { TiTicket } from "react-icons/ti";

export { FiChevronDown, FiChevronRight } from "react-icons/fi";

export { AiOutlineQuestionCircle } from "react-icons/ai";

export {
  MdDentistry,
  MdPromo,
  MdQuote,
  Mds360Round,
  MdsContentCopyOutlined,
  MdsIdCardOutlined,
  MdsLocalPharmacyOutlined,
  MdsRavenOutlined,
  MdsSoundDetectionDogBarkingRound,
  MdsSpaOutlined,
  MdsStethoscopeOutlined,
  MdsSurgicalOutlined,
  MdsSurgicalSharp,
  MdsSyringeOutlined,
  MdsUrgentCare,
  MdsVaccinesOutlined,
  MdTibia,
  MdTrust,
};
